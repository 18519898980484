import { API } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

type MatchProps = {
  token: string;
};
const VerifyEmailScene = (props: RouteComponentProps<MatchProps>) => {
  const {
    match: {
      params: { token },
    },
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isEmailVerified, setEmailVerified] = useState(true);

  useEffect(() => {

    const verifyEmail = async () => {

      try {
        const response = await API.post('GlueRest', '/accounts/verify-email', {
          body: {
            token,
          },
        });


        if (response.error) {
          setEmailVerified(false);
          setIsLoading(false);
        } else {
          setEmailVerified(true);
          setIsLoading(false);
        }
      } catch (e) {
        console.log('error', e);
        setEmailVerified(false);
        setIsLoading(false);
      }
    };

    verifyEmail();
  }, []);

  let title = 'Loading';
  let description = "Won't be long!";

  if (!isLoading) {
    if (isEmailVerified) {
      title = 'Email Verified';
      description = 'You can now close this window';
    } else {
      title = 'There was an issue verifying your email';
      description = 'Please try again or contact support';
    }
  }
  return (
    <div className="App">
      <header className="App-header">
        <p>{title}</p>
        {description && <p className="smaller">{description}</p>}
      </header>
    </div>
  );
};

export default VerifyEmailScene;
