import { Button, Form, message, Input, Space } from 'antd';

import { API, Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  CheckCircleOutlined,
  WarningOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

type MatchProps = {
  token: string;
};



const passwordStrengthRule = (updateErrors:any) => ({
  validator(rule:any, value:any) {
    const errors = [];

    if (value.length < 8) {
      errors.push('minLength');
    }

    if (!/[A-Z]/.test(value)) {
      errors.push('includeUppercaseLetter');
    }

    if (!/[a-z]/.test(value)) {
      errors.push('includeLowercaseLetter');
    }

    if (!/[0-9]/.test(value)) {
      errors.push('includeNumber');
    }

    if (!/[^A-Za-z0-9 ]/.test(value)) {
      errors.push('includeSpecialCharacter');
    }
    updateErrors(errors);

    if (errors.length > 0) {
      return Promise.reject(errors);
    }

    return Promise.resolve();
  },
});


 const buildPasswordErrorMessages = (errors:any, showCompleted = false) => {
  const allErrors :any= {
    minLength: 'be at least 8 characters long',
    includeUppercaseLetter: 'include an uppercase letter',
    includeLowercaseLetter: 'include a lowercase letter',
    includeNumber: 'include a number',
    includeSpecialCharacter: 'include a special character (!?#)',
  };

  const returnErrors = [];

  for (const key in allErrors) {
    const currentError = allErrors[key];

    returnErrors.push(
      <span
        style={{
          color: !showCompleted ? '#888' : errors.includes(key) ? 'red' : 'green',
        }}
      >
        <Space>
          {showCompleted ? (
            errors.includes(key) ? (
              <WarningOutlined />
            ) : (
              <CheckCircleOutlined />
            )
          ) : (
            <InfoCircleOutlined />
          )}

          {currentError}
        </Space><br />
      </span>
    );
  }

  return returnErrors;
};


const NewPasswordScene = (props: RouteComponentProps<MatchProps>) => {
  const {
    match: {
      params: { token },
    },
  } = props;

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState([]);

  const [success, setSuccess] = useState(false);

  let realToken: string = token;
  const tokenParts = token.split('-');
  let userId: string = '';
  let useAmplifyAuth = false;

  if (tokenParts[tokenParts.length - 1].length < 10) {
    realToken = tokenParts.pop() as string;
    userId = tokenParts.join('-');
    useAmplifyAuth = true;
  }

  const onFinish = async (values: any) => {
    console.log('values', values);
    setIsLoading(true);
    try {
      if (useAmplifyAuth) {
        await Auth.forgotPasswordSubmit(userId, realToken, values.password);
        setSuccess(true);
      } else {
        const response = await API.post(
          'GlueRest',
          '/accounts/reset-password',
          {
            body: {
              token: realToken,
              password: values.password,
            },
          }
        );

        if (response.error) {
          message.error(response.error);
          form.resetFields();
        } else {
          setSuccess(true);
        }
      }

      setIsLoading(false);
    } catch (e: any) {
      console.log('error', e);
      message.error(e.message);
      form.resetFields();
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        {!success ? (
          <Form onFinish={onFinish} form={form} layout="vertical">
            <Form.Item
              name="password"
              label="New Password"
              rules={[
                passwordStrengthRule((err:any) => {
                  setPasswordError(err);
                }),
              ]}
              help={buildPasswordErrorMessages(
                passwordError,
                form.isFieldTouched('password')
              )}
            >
              <Input.Password disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm New Password"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!'
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password disabled={isLoading} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        ) : null}
        {success ? (
          <>
            <p>Password Reset</p>
            <p className="smaller">You can now close this window</p>
          </>
        ) : null}
      </header>
    </div>
  );
};

export default NewPasswordScene;
