import { Amplify } from 'aws-amplify';
import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
} from 'react-router-dom';
import './App.css';
import NewPasswordScene from './scenes/NewPasswordScene';
import VerifyEmailScene from './scenes/VerifyEmailScene';

let appNames = {
  '9be7ece3-c93d-4ece-8cbc-db3d9be23066': 'Niko Algieri',
  'dfc7460a-3a14-49d0-a405-c77ad2c1b653': 'SantéFit',
};
let segs = document.location.href.split('/');
let keyToCut = '';

if (segs.includes('verify-email')) {
  keyToCut = 'verify-email';
}
if (segs.includes('reset-password')) {
  keyToCut = 'reset-password';
}
const ids = segs.slice(segs.indexOf(keyToCut) + 1);
let appName = '';

if (ids.length > 1) {
  appName = appNames[ids[0]];
}
console.log(ids);

const appConfigs = {
  '9be7ece3-c93d-4ece-8cbc-db3d9be23066': {
    API: {
      endpoints: [
        {
          endpoint:
            'https://fn38iz0ha0.execute-api.eu-west-2.amazonaws.com/dev',
          name: 'GlueRest',
        },
      ],
    },
    aws_appsync_graphqlEndpoint:
      'https://xzgvjy4uk5hqvd3ilk4nsdkboy.appsync-api.eu-west-2.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-2',
    aws_cognito_identity_pool_id:
      'eu-west-2:4c80aa50-4ad9-4dc6-b3e3-78c413364723',
    aws_cognito_region: 'eu-west-2',
    aws_user_files_s3_bucket: 'niko-algieri-demo-cdn-dev',
    aws_user_files_s3_bucket_region: 'eu-west-2',
    aws_user_pools_id: 'eu-west-2_mwlWDB8Lw',
    aws_user_pools_web_client_id: '5sikl0e8aatg7dgbo7bffa0si2',
    Storage: {
      AWSS3: {
        bucket: 'niko-algieri-demo-cdn-dev',
        region: 'eu-west-2',
      },
    },
  },
  '2e76306d-839d-4e00-b71d-8802b2222e19': {
    API: {
      endpoints: [
        {
          endpoint:
            'https://dpejf4l8ai.execute-api.eu-west-2.amazonaws.com/prod',
          name: 'GlueRest',
        },
      ],
    },
    aws_appsync_graphqlEndpoint:
      'https://3vx47ccgnvdbleoue7qudrwqby.appsync-api.eu-west-2.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-2',
    aws_cognito_identity_pool_id:
      'eu-west-2:42e7c9a0-b552-47e5-9094-83415d208d54',
    aws_cognito_region: 'eu-west-2',
    aws_user_files_s3_bucket: 'sante-cdn-prod',
    aws_user_files_s3_bucket_region: 'eu-west-2',
    aws_user_pools_id: 'eu-west-2_xde9ZlYmA',
    aws_user_pools_web_client_id: '2b5vm73hi3jkrmere7ulcop540',
  },
};

if (ids.length > 1) {
  Amplify.configure({
    oauth: {},
    aws_cognito_username_attributes: ['EMAIL'],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: ['SMS'],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
    aws_appsync_authenticationType: 'API_KEY',
    ...appConfigs[ids[0]],
  });
}

function App() {
  return (
    <Router>
      <Switch>
        <Route
          path="/verify-email/:appId/:token"
          component={VerifyEmailScene}
        />
        <Route
          path="/reset-password/:appId/:token"
          component={NewPasswordScene}
        />
        <Route path="*">
          <div className="App">
            <header className="App-header">
              <p>Please open this link on a device with the {appName} app</p>
            </header>
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export { App };
